import styled from 'styled-components';

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 4rem;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.white};
  font-family: ${({ theme }) => theme.fontHeader};
  font-size: 1.25rem;
  font-weight: bold;
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.grey200};
  margin: 1rem 0;
`;

export const DateWrapper = styled.div`
  display: flex;
  flex: 1 1 172px;
  flex-flow: row;
  align-items: center;

  color: ${({ theme }) => theme.grey400};
  font-family: ${({ theme }) => theme.fontHeader};

  margin-bottom: 1rem;

  .date-bit:not(:first-child) {
    margin-left: 0.5rem;
  }
`;

export const Year = styled.div`
  font-size: 1.5rem;

  @media only screen and (min-width: 768px) {
    font-size: 1rem;
  }
`;

export const Month = styled.div`
  font-size: 1.5rem;

  @media only screen and (min-width: 768px) {
    font-size: 1.25rem;
  }
`;

export const Day = styled.div`
  font-size: 1.5rem;

  @media only screen and (min-width: 768px) {
    font-size: 3rem;
  }
`;

export const Content = styled.div`
  flex: 8 1 476px;
  font-family: ${({ theme }) => theme.fontBody};
`;

export const Time = styled.div`
  color: ${({ theme }) => theme.grey400};
`;

export const Location = styled.div`
  color: ${({ theme }) => theme.grey400};
  font-family: ${({ theme }) => theme.fontBody};

  display: flex;
  align-items: center;

  margin-top: 1rem;
`;

export const Icon = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  border: 2px solid ${({ theme }) => theme.grey300};
  border-radius: 100%;

  margin-right: 0.5rem;

  height: 24px;
  width: 24px;
`;
