import React from 'react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { graphql, Link, StaticQuery } from 'gatsby';
import { Button, Typography } from 'amphitheatre';
import styled from 'styled-components';
import { StaticImage, IGatsbyImageData } from 'gatsby-plugin-image';

import Layout from 'src/Layout';

import CallToAction from 'src/Components/CallToAction';
import Container from 'src/Components/ContentContainer';
import EventList from 'src/Components/EventListHome';
import News from 'src/Components/News';
import SEO from 'src/Components/SEO';
import SignupNewsletter from 'src/Components/SignupNewsletter';

import * as Base from 'src/page-styles/base.styled';
import * as Hero from 'src/page-styles/hero.styled';

dayjs.extend(advancedFormat);

const { Header, Paragraph } = Typography;

interface Props {
  data: {
    allMarkdownRemark: {
      edges: Array<{
        node: {
          parent: {
            name: string;
            relativeDirectory: string;
          };
          frontmatter: {
            title: string;
            date: string;
            display_image: IGatsbyImageData;
          };
        };
      }>;
    };
    latest_events: {
      edges: Array<{
        node: {
          parent: {
            name: string;
            relativeDirectory: string;
          };
          frontmatter: {
            title: string;
            description: string;
            date: string;
          };
        };
      }>;
    };
  };
}

const EventWrappers = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  @media only screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  .event-list {
    border: 1px solid ${({ theme }) => theme.primary500};
    margin: 0;
    padding: 1rem;
  }
`;

const Options = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  @media only screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Option = styled.div`
  display: flex;
  flex-flow: column;
`;

const OptionContent = styled.div`
  flex-grow: 1;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  @media only screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const Home: React.FunctionComponent<Props> = ({ data }: Props) => {
  const { allMarkdownRemark, latest_events } = data;
  const { edges: prs } = allMarkdownRemark;
  const { edges: latestEvents } = latest_events;

  return (
    <>
      <SEO
        title="Saber Astronautics | Cutting Edge Space Operations"
        description="Making space flight easy. Saber is a global provider of next generation space visualisation products and services for planning and operation missions."
      />
      <Layout>
        <Hero.Wrapper>
          <Hero.Cover />
          <StaticImage
            className="hero-image"
            src="../images/sunset.png"
            alt="hero"
          />
          <Hero.Text>
            <Hero.MajorHeader>
              Global <Base.Yellow>Space Operations</Base.Yellow> Provider
            </Hero.MajorHeader>
            <Hero.Header style={{ maxWidth: '1024px', margin: 'auto' }}>
              The next generation of space technology
            </Hero.Header>
          </Hero.Text>
        </Hero.Wrapper>
        <Container.Dark>
          <Options>
            <Option className="option">
              <OptionContent>
                <Header level={3} style={{ fontWeight: 'bold' }}>
                  Services
                </Header>
                <Base.Paragraph>
                  Conducting a space mission? Our Responsive Space Operations
                  Centers offer accurate, secure, and safe space missions. Fly
                  with us today
                </Base.Paragraph>
              </OptionContent>
              <Link to="/service-overview">
                <Button fill>Services</Button>
              </Link>
            </Option>
            <Option className="option">
              <OptionContent>
                <Header level={3} style={{ fontWeight: 'bold' }}>
                  Products
                </Header>
                <Base.Paragraph>
                  Space software that is easy to use, easy to train, and highly
                  accurate. Explore the beauty and depth of software by Saber
                  Astronautics
                </Base.Paragraph>
              </OptionContent>
              <Link to="/pigi">
                <Button fill>View P.I.G.I.</Button>
              </Link>
            </Option>
          </Options>
          <Base.Gap />
        </Container.Dark>
        <Container.Dark>
          <Header level={3} style={{ fontWeight: 'bold' }}>
            Upcoming Events
          </Header>
          <Base.Paragraph>
            Saber is committed to the democratization of space. We participate
            in a number of events within the space community. Come meet our
            team.
          </Base.Paragraph>

          {latestEvents.length ? (
            <EventWrappers>
              {latestEvents.map((latestEvent, index) => {
                const { title, date, description } =
                  latestEvent.node.frontmatter;

                return (
                  <EventList
                    key={`${title}-${index}`}
                    className="event-list"
                    title={title}
                    description={description}
                    date={date}
                  />
                );
              })}
            </EventWrappers>
          ) : (
            <div className="content">
              <Paragraph>No upcoming events</Paragraph>
            </div>
          )}
          <Base.Gap />
        </Container.Dark>
        <Container.Dark>
          <Header level={3} style={{ fontWeight: 'bold' }}>
            Latest News
          </Header>
          <ContentWrapper>
            {prs.map((pr, index) => {
              const { title, date, display_image } = pr.node.frontmatter;
              const { name, relativeDirectory } = pr.node.parent;

              return (
                <News
                  key={`${title}-${index}`}
                  href={`/${relativeDirectory}/${name}/`}
                  title={title}
                  date={date}
                  displayImage={display_image}
                />
              );
            })}
          </ContentWrapper>
        </Container.Dark>
        <SignupNewsletter />
        <CallToAction
          header="LET'S BEGIN"
          linkText="Get in contact today"
          to="/contact-us/"
        />
      </Layout>
    </>
  );
};

export default (): JSX.Element => (
  <StaticQuery
    query={graphql`
      query IndexQuery {
        allMarkdownRemark(
          limit: 3
          sort: { fields: frontmatter___date, order: DESC }
          filter: { frontmatter: { type: { eq: "pr" } } }
        ) {
          edges {
            node {
              parent {
                ... on File {
                  name
                  relativeDirectory
                }
              }
              frontmatter {
                title
                date
                display_image {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
        }

        latest_events: allMarkdownRemark(
          limit: 2
          sort: { fields: frontmatter___date, order: ASC }
          filter: {
            frontmatter: { type: { eq: "events" } }
            isFuture: { eq: true }
          }
        ) {
          edges {
            node {
              frontmatter {
                title
                description
                date
              }
            }
          }
        }
      }
    `}
    render={(data) => <Home data={data} />}
  />
);
