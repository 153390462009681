import React from 'react';
import styled from 'styled-components';
import { Typography } from 'amphitheatre';
import { StaticImage } from 'gatsby-plugin-image';

const { Header } = Typography;

const Constrain = styled.div`
  margin: auto;
  max-width: 900px;
  padding: 5rem 0 2rem;
  position: relative;
  z-index: 2;

  @media only screen and (min-width: 768px) {
    padding: 12rem 0 2.5rem;
  }
`;

const Wrapper = styled.div`
  background: black;
  position: relative;

  .newsletter-image {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
`;

const MailchimpWrapper = styled.div`
  #mc_embed_signup form {
    box-sizing: border-box;
    display: block;
    position: relative;
    text-align: left;
    padding: 10px;
  }

  #mc_embed_signup h2 {
    font-weight: bold;
    padding: 0;
    margin: 15px 0;
    font-size: 1.4em;
  }

  #mc_embed_signup input {
    border: 1px solid #999;
    -webkit-appearance: none;
  }

  #mc_embed_signup input[type='checkbox'] {
    -webkit-appearance: checkbox;
  }

  #mc_embed_signup input[type='radio'] {
    -webkit-appearance: radio;
  }

  #mc_embed_signup input:focus {
    outline: none;
  }

  #mc_embed_signup .button {
    background: #f18f01;
    border: 2px solid #f18f01;
    color: #f8f7f6;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    padding: 0.75rem 2rem;
    text-align: center;
    text-decoration: none;
    transition: all 0.23s ease-in-out 0s;
    width: 100%;
  }

  #mc_embed_signup .button:hover {
    background-color: #c17201;
    border: 2px solid #c17201;
  }

  #mc_embed_signup .small-meta {
    font-size: 11px;
  }

  #mc_embed_signup .nowrap {
    white-space: nowrap;
  }

  #mc_embed_signup .clear {
    clear: none;
    display: inline;
    width: 100%;

    @media only screen and (min-width: 768px) {
      width: auto;
    }
  }

  #mc_embed_signup label {
    display: block;
    font-size: 16px;
    padding-bottom: 10px;
    font-weight: bold;
  }

  #mc_embed_signup input.email {
    flex: 1;
    font-family: 'Open Sans', 'Helvetica Neue', Arial, Helvetica, Verdana,
      sans-serif;
    font-size: 16px;
    display: block;
    padding: 0.75rem;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    width: calc(100% - 1.5rem);
  }

  #mc_embed_signup div#mce-responses {
    float: left;
    top: -1.4em;
    padding: 0em 0.5em 0em 0.5em;
    overflow: hidden;
    width: 90%;
    margin: 0 5%;
    clear: both;
  }

  #mc_embed_signup div.response {
    margin: 1em 0;
    padding: 1em 0.5em 0.5em 0;
    font-weight: bold;
    float: left;
    top: -1.5em;
    z-index: 1;
    width: 80%;
  }

  #mc_embed_signup #mce-error-response {
    display: none;
  }

  #mc_embed_signup #mce-success-response {
    color: #529214;
    display: none;
  }

  #mc_embed_signup label.error {
    display: block;
    float: none;
    width: auto;
    margin-left: 1.05em;
    text-align: left;
    padding: 0.5em 0;
  }

  #mc_embed_signup {
    font: 14px Poppins, Arial, sans-serif;
  }

  #mc_embed_signup_scroll {
    display: flex;
    align-items: center;
    flex-flow: column;

    @media only screen and (min-width: 768px) {
      flex-flow: row;
    }
  }

  #mc_embed_signup div.spacer {
    padding: 0.5rem;
  }
`;

function UserInput() {
  return (
    <MailchimpWrapper>
      <div id="mc_embed_signup">
        <form
          action="https://saberastro.us10.list-manage.com/subscribe/post?u=252d103fc854a790d1ef8a4eb&amp;id=f220a82eae"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          noValidate
        >
          <div id="mc_embed_signup_scroll">
            <input
              type="email"
              name="EMAIL"
              className="email"
              id="mce-EMAIL"
              placeholder="email address"
              required
            />
            <div
              style={{ position: 'absolute', left: '-5000px' }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_252d103fc854a790d1ef8a4eb_f220a82eae"
                tabIndex={-1}
                value=""
              />
            </div>
            <div className="spacer" />
            <div className="clear">
              <input
                type="submit"
                value="Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
                className="button"
              />
            </div>
          </div>
        </form>
      </div>
    </MailchimpWrapper>
  );
}

function SignupNewsletter(): JSX.Element {
  return (
    <Wrapper>
      <StaticImage
        className="newsletter-image"
        src="../../images/clean-sun.png"
        alt="Newsletter"
        objectPosition="50% top"
      />
      <Constrain>
        <Header level={4} style={{ textAlign: 'center' }}>
          Sign up for the latest updates
        </Header>
        <br />
        <UserInput />
      </Constrain>
    </Wrapper>
  );
}

export default SignupNewsletter;
